import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import Image from 'next/image';
import clsx from 'clsx';
import ReactGA from 'react-ga4';

import config from 'config';

import { ESegmentCategory, track } from 'services/analytics';
import { createLead, CreateLeadDto } from 'services/lead.services';
import { useInitPosition } from 'public/scripts/common';
import { queryParser } from 'utils/queryParser';
import HeadComponent from 'components/Head';
import MainLayout from 'components/MainLayout';
import Testimonials from 'components/Testimonials';
import StatesWithJobs from 'components/StatesWithJobs';
import { DarkButton } from 'components/Buttons/DarkButton';
import { SignedUp } from 'components/SignedUp';
import { SignedUpByPhone } from 'components/SignedUp/phone';
import { SignUpSuccessful } from 'components/SignUpSuccessful';
import { AlertError } from 'components/Alerts/AlertError';

import styles from './styles.module.scss';
import { AppLocalStorage } from '../../../utils/storage';
import { EGtmEvents, gtmPushEvent } from 'services/googleTagManager';

ReactGA.initialize(config.googleOptimizeTagManager);

export default function Drivers() {
  const router = useRouter();
  const [t] = useTranslation('driver');
  useInitPosition();

  const [openSignUpForm, setOpenSignUpForm] = useState<boolean>(false);
  const [openSignUpSuccessful, setOpenSignUpSuccessful] =
    useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [variantId, setVariantId] = useState<number>();

  useEffect(() => {
    let variant = AppLocalStorage.getNumber('googleOptimizeSignUpVariantId');
    if (variant === undefined || isNaN(variant)) {
      variant = Math.round(Math.random());
      AppLocalStorage.setValue('googleOptimizeSignUpVariantId', variant);
    }
    setVariantId(variant);
  }, []);

  const queries = useMemo(
    () =>
      queryParser<CreateLeadDto>(
        router.asPath.replace(`${router.pathname}`, '')
      ),
    [router.asPath]
  );

  const isQueryExist = useMemo(
    () => Object.keys(router.query).length,
    [router.query]
  );

  useEffect(() => {
    if (isQueryExist) {
      track(ESegmentCategory.VISIT_LANDING_WITH_LEADS, {
        ...router.query,
      });
    }
  }, [router.query, track]);

  const handleOnOpenSignUp = async () => {
    ReactGA.event({
      category: 'optimize.activate',
      action: 'optimize.activate',
      label: 'optimize.activate',
      value: 1,
    });

    ReactGA.gtag('event', 'experiment_impression', {
      experiment_id: config.googleOptimizeExperimentId,
      variant_id: `${config.googleOptimizeExperimentId}.${variantId}`,
      send_to: config.googleOptimizeTagManager,
    });

    gtmPushEvent(EGtmEvents.SignUpClick);
    if (isQueryExist) {
      track(ESegmentCategory.LANDING_DRIVER_SIGN_UP_OPEN_FORM, {
        ...router.query,
      });
    }

    setOpenSignUpForm(true);
  };

  const handleOnSubmitSignUp = (v: any) => {
    createLead(v)
      .then(() => {
        ReactGA.event({
          category: 'create_lead',
          action: 'create_lead',
          label: 'created_lead_successfully',
          value: 1,
        });
        gtmPushEvent(EGtmEvents.DriverLead);
        if (isQueryExist) {
          track(ESegmentCategory.LANDING_DRIVER_LEAD, {
            ...router.query,
          });
        }

        setOpenSignUpForm(false);
        setOpenSignUpSuccessful(true);
        setError('');
      })
      .catch((error) => {
        setError(error.message);
      });
  };

  return (
    <>
      <HeadComponent
        title={t('head.title')}
        description={t('head.description')}
      />
      <MainLayout>
        <div>
          {/* Main Landing Page */}
          <div className="container-fluid container-first driver-top-container">
            <div className="row mt-0">
              <div className="col-12">
                <div className="row row-block">
                  <div className="col-12 col-lg-6 driver-signup-number-container">
                    <div className="driver-signup-number">
                      <h1 className={styles.bannerTitle}>{t('bannerTitle')}</h1>
                      <div className="w-100">
                        {error && (
                          <AlertError
                            message={error}
                            onClose={() => setError('')}
                            className={styles.alertError}
                          />
                        )}
                        <DarkButton
                          className={styles.ctaButton}
                          onClick={handleOnOpenSignUp}
                        >
                          {t('signUpButton')}
                        </DarkButton>
                        {openSignUpForm &&
                          (variantId === 0 ? (
                            <SignedUp
                              onSubmit={handleOnSubmitSignUp}
                              values={queries}
                              landingURL={config.baseUrl + router.pathname}
                            />
                          ) : (
                            <SignedUpByPhone
                              onSubmit={handleOnSubmitSignUp}
                              values={queries}
                              landingURL={config.baseUrl + router.pathname}
                            />
                          ))}
                        {openSignUpSuccessful && (
                          <SignUpSuccessful
                            title={t('signedUp.title')}
                            subtitle={t('signedUp.subtitle')}
                            text={t('signedUp.textSuccess')}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-lg-6 p-0 pt-lg-1 driver-top-image-container">
                    <div className="driver-top-image">
                      <div>
                        <Image
                          src="/images/driver/haul-hero.jpg"
                          alt="CDL Driver"
                          width="100"
                          height="100"
                          objectFit="cover"
                          layout="responsive"
                          priority
                        />
                      </div>
                      <div>
                        <Image
                          src="/images/driver/hand-hero.png"
                          alt="Haul App"
                          width="100"
                          height="100"
                          objectFit="contain"
                          layout="responsive"
                          priority
                          className={styles.phoneHandImg}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Second Section */}
          <div
            className={clsx(
              'container-second driver-container-second with-background',
              styles.bgStore
            )}
          >
            <div className="container">
              <div className="row row-block pt-1 pb-0">
                <div className="col-12 col-lg-6">
                  <h2 className="howHaulWorks">
                    <span>{t('whyJoinHaulDriver.titleP1')} </span>
                    <span className="highlight-container">
                      <span className={styles.highlight}>
                        {t('whyJoinHaulDriver.titleP2')}{' '}
                      </span>
                    </span>
                  </h2>
                </div>
              </div>
            </div>

            <div className={styles.storeIconContainer}>
              <a href="https://apps.apple.com/us/app/haul-driver/id1524004807">
                <Image
                  src="/images/app-stores/app-store-black-2.svg"
                  alt="App Store"
                  width={149}
                  height={50}
                />
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.haul">
                <Image
                  src="/images/app-stores/google-store-black-2.svg"
                  alt="Google Play Store"
                  width={168}
                  height={50}
                />
              </a>
            </div>
          </div>

          {/* How Haul Works Section */}
          <div className="about-haul-container">
            <div className="container">
              <div className="row">
                <div className="col mt-4 mb-4 mb-lg-5">
                  <h2
                    className={clsx(
                      styles.bannerTitle,
                      styles.bannerTitleSecond,
                      styles.colorPrimaryLight
                    )}
                  >
                    {t('howItWorks.title')}
                  </h2>
                </div>
              </div>
              <div className="wrap-how-work-items">
                <div className="swg-road4 d-none d-lg-block">
                  <svg
                    width="630"
                    height="366"
                    viewBox="0 0 630 366"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3 3.0007V169.927C3 186.516 16.4315 199.964 33 199.964H597C613.569 199.964 627 213.412 627 230.001V363.001"
                      stroke="#E6F868"
                      strokeWidth="5"
                      strokeLinecap="square"
                      strokeLinejoin="bevel"
                      strokeDasharray="4 15"
                    />
                  </svg>
                </div>
                <div className={clsx('row row-block br1', styles.howItWorks)}>
                  <div className={clsx('col-5 car-item', styles.carItem)}>
                    <div className="row">
                      <div className="col-12 d-flex">
                        <span
                          className={clsx('road-number', styles.roadNumber)}
                        >
                          # 1
                        </span>
                        <div>
                          <span className="car-item-title">
                            {t('howItWorks.first.titleNew')}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-lg">
                    <div className="block-img">
                      <Image
                        src="/images/driver/driverLicense.svg"
                        alt="haul"
                        width={460}
                        height={252}
                        objectFit="contain"
                      />
                    </div>
                  </div>
                </div>
                <div
                  className={clsx('row row-block br2', styles.preferencesBlock)}
                >
                  <div
                    className={clsx(
                      'col-12 col-lg order-last order-lg-first col-schedule-img',
                      styles.preferencesBlockWrapperImg
                    )}
                  >
                    <div
                      className={clsx(
                        'block-img d-inline-block',
                        styles.preferencesBlockImg
                      )}
                    >
                      <Image
                        src="/images/driver/preferences.svg"
                        alt="haul"
                        width={450}
                        height={228}
                        objectFit="contain"
                      />
                    </div>
                  </div>
                  <div
                    className={clsx(
                      'col-5 col-xl-3 order-first order-lg-last car-item',
                      styles.carItemTitle
                    )}
                  >
                    <div className="row">
                      <div
                        className={clsx(
                          'col-12 d-flex col-lg-8',
                          styles.preferencesTitle
                        )}
                      >
                        <span
                          className={clsx('road-number', styles.roadNumber)}
                        >
                          # 2
                        </span>
                        <div className="car-item-second">
                          <span className="car-item-title">
                            {t('howItWorks.second.titleNew')}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="swg-road6 d-none d-lg-block">
                  <svg
                    width="630"
                    height="473"
                    viewBox="0 0 630 473"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M627 2.50073V220.964C627 237.553 613.569 251.001 597 251.001H33C16.431 251.001 3 264.449 3 281.038V470.5"
                      stroke="#E6F868"
                      strokeWidth="5"
                      strokeLinecap="square"
                      strokeLinejoin="bevel"
                      strokeDasharray="4 15"
                    />
                  </svg>
                </div>
                <div className={clsx('row row-block', styles.pickJob)}>
                  <div className="col col-lg-4 car-item">
                    <div className="row">
                      <div className="col-12 d-flex">
                        <span
                          className={clsx('road-number', styles.roadNumber)}
                        >
                          # 3
                        </span>
                        <div className="car-item-third">
                          <span className="car-item-title">
                            {t('howItWorks.third.title')}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-lg br3">
                    <div style={{ marginLeft: -25 }}>
                      <div className={clsx('block-img', styles.jobCard)}>
                        <Image
                          src="/images/driver/jobCard.svg"
                          alt="haul"
                          width={410}
                          height={272}
                          objectFit="contain"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Testimonial Section */}
            <div
              className={clsx(
                'container testimonials-container',
                styles.testimonialsContainer
              )}
            >
              <div className="testimonials-section">
                <Testimonials className={styles.testimonials} />
              </div>
            </div>
          </div>

          {/* Always on Hand Section */}
          <StatesWithJobs className={styles.statesWithJobs} />
        </div>
      </MainLayout>
    </>
  );
}
