import clsx from 'clsx';
import Image from 'next/image';
import Link from 'next/link';
import { useTranslation } from 'react-i18next';

interface Props {
  smallPaddingTop?: boolean;
  className?: string;
}

function StatesWithJobs({ smallPaddingTop = false, className }: Props) {
  const [t] = useTranslation('driver');

  return (
    <div
      className={clsx(
        'container-jobs with-background',
        {
          'small-padding': smallPaddingTop === true,
        },
        className
      )}
    >
      <div className="container">
        {/* Section on Desktop */}
        <div className="row row-block d-none d-lg-flex">
          <div className="col-4 phone-lg">
            <Image
              src="/images/driver/phone.svg"
              alt="App listing jobs"
              width={308}
              height={432}
            />
          </div>

          <div className="col-8">
            <h2 className="banner-title">{t('jobs.title')}</h2>
            <h5 className="jobs-in">{t('jobs.findJobIn')}</h5>
            <div className="state-btn-container">
              <div>
                <Link href="/cdl-drivers/cdl-jobs/CA">
                  <div className="btn-style states pointer">
                    <span>California</span>
                  </div>
                </Link>
              </div>
              <div>
                <Link href="/cdl-drivers/cdl-jobs/TX">
                  <div className="btn-style states pointer">
                    <span>Texas</span>
                  </div>
                </Link>
              </div>
              <div>
                <Link href="/cdl-drivers/cdl-jobs/GA">
                  <div className="btn-style states pointer">
                    <span>Georgia</span>
                  </div>
                </Link>
              </div>
              <div>
                <Link href="/cdl-drivers/cdl-jobs/PA">
                  <div className="btn-style states pointer">
                    <span>Pennsylvania</span>
                  </div>
                </Link>
              </div>
              <div>
                <Link href="/cdl-drivers/cdl-jobs">
                  <div className="btn-style states pointer btn-more">
                    <span>{t('alwaysAtHand.more')}...</span>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>

        {/* Section on Mobile */}
        <div className="row row-block d-sm-flex d-md-flex d-lg-none">
          <div className="col-12">
            <h2 className="banner-title">{t('jobs.title')}</h2>
            <h5 className="jobs-in">{t('jobs.findJobIn')}</h5>
          </div>
          <div className="col-12 col-lg-8 state-btn-container">
            <div>
              <Link href="/cdl-drivers/cdl-jobs/CA">
                <div className="btn-style states pointer">
                  <span>California</span>
                </div>
              </Link>
            </div>
            <div>
              <Link href="/cdl-drivers/cdl-jobs/TX">
                <div className="btn-style states pointer">
                  <span>Texas</span>
                </div>
              </Link>
            </div>
            <div>
              <Link href="/cdl-drivers/cdl-jobs/GA">
                <div className="btn-style states pointer">
                  <span>Georgia</span>
                </div>
              </Link>
            </div>
            <div>
              <Link href="/cdl-drivers/cdl-jobs/PA">
                <div className="btn-style states pointer">
                  <span>Pennsylvania</span>
                </div>
              </Link>
            </div>
            <div>
              <Link href="/cdl-drivers/cdl-jobs">
                <div className="btn-style states pointer btn-more">
                  <span>{t('alwaysAtHand.more')}...</span>
                </div>
              </Link>
            </div>
          </div>

          <div className="phone-sm">
            <Image
              src="/images/driver/phone.svg"
              alt="App listing jobs"
              width={308}
              height={433}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default StatesWithJobs;
