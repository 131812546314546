import { useTranslation } from 'react-i18next';
import Image from 'next/image';
import clsx from 'clsx';
import styles from './index.module.scss';

type InputProps = {
  className?: string;
};

function Testimonials({ className }: InputProps) {
  const [t] = useTranslation('driver');

  return (
    <div className={clsx(styles.testimonials, className)}>
      <div className={styles.item}>
        <div>
          <div className={styles.photo}>
            <Image
              src="/images/driver/alber-flores.jpg"
              alt="haul"
              width={60}
              height={60}
            />
          </div>
          <div className={styles.driverInfo}>
            <span>Albert Flores</span>
            <span>Haul MVP - 13 Years Exp.</span>
          </div>
        </div>
        <p>{t('testimonials.first')}</p>
      </div>

      <div className={styles.item}>
        <div>
          <div className={styles.photo}>
            <Image
              src="/images/driver/dale-palmer.jpg"
              alt="haul"
              width={60}
              height={60}
            />
          </div>
          <div className={styles.driverInfo}>
            <span>Dale Palmer</span>
            <span>Haul MVP - 4 Years Exp.</span>
          </div>
        </div>
        <p>{t('testimonials.second')}</p>
      </div>
    </div>
  );
}

export default Testimonials;
