import Image from 'next/image';
import { useTranslation } from 'react-i18next';
import FormInputRow from '../InputRow';
import FormInput from '../Input';
import useFormik from '../../utils/hooks/useFormik';

import styles from './styles.module.scss';
import { phoneValidationSchema } from './phoneValidationSchema';
import { useMemo } from 'react';
import lodash from 'lodash';
import clsx from 'clsx';
import { formatAsPhone } from '../../utils/formatAsPhone';

type InputProps = {
  values?: any;
  landingURL?: string;
  onSubmit: (v: any) => void;
};

const presetDataFields = [
  'source',
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_term',
  'utm_content',
  'pt',
  'ct',
  'mt',
  'vt',
  'lastName',
  'firstName',
  'email',
  'zip',
  'orderId',
  'order',
];

export const SignedUpByPhone = ({
  onSubmit,
  values,
  landingURL,
}: InputProps) => {
  const [t] = useTranslation('driver');

  const presetData = useMemo(
    () => lodash.pick(values, presetDataFields),
    [values]
  );

  const { getFieldProps, getFieldErrorProp, handleSubmit } = useFormik({
    initialValues: {
      phone:
        formatAsPhone(values?.phoneNumber) ||
        formatAsPhone(values?.phone) ||
        '',
    },
    validationSchema: phoneValidationSchema({
      phone: t('errors.phoneRequired'),
    }),
    onSubmit(values) {
      onSubmit({
        landingURL,
        ...presetData,
        ...values,
        phone: `+1${values.phone}`,
      });
    },
  });

  return (
    <div className={styles.root}>
      <div className={styles.content}>
        <h2 className={styles.title}>{t('signedUp.titlePhone')}</h2>
        <div className={styles.subtitle}>{t('signedUp.subtitle')}</div>
        <form onSubmit={handleSubmit} className={styles.form}>
          <FormInputRow required {...getFieldErrorProp('phone')}>
            <FormInput
              prefix="+1"
              {...getFieldProps('phone')}
              className={clsx(styles.input, styles.phonePrefix)}
              placeholder={t('signedUp.placeholders.phone')}
              onChange={(value) => {
                getFieldProps('phone').onChange(
                  formatAsPhone(value.toString(), getFieldProps('phone').value)
                );
              }}
            />
          </FormInputRow>
          <button type="submit" className={styles.submitBtn}>
            <span>{t('signedUp.button')}</span>
            <Image
              src="/images/arrow-right.svg"
              alt=""
              width={18}
              height={10}
            />
          </button>
        </form>
      </div>
    </div>
  );
};
