import config from '../config';

export type CreateLeadDto = {
  source?: string;
  utm_source?: string;
  utm_medium?: string;
  utm_campaign?: string;
  utm_term?: string;
  utm_content?: string;
  pt?: string;
  ct?: string;
  mt?: string;
  vt?: string;
  landingURL?: string;
  phone: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  orderId?: string;
  order?: string;
  communityJobId?: string;
};

export async function createLead(lead: CreateLeadDto) {
  const res = await fetch(`${config.apiUrl}/link-sharing/lead-sms`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(lead),
  });
  const data = await res.json();
  if (data.statusCode >= 400) {
    const message = data?.message
      ? data.message
      : 'An error occurred while trying to save data';
    throw new Error(message);
  }
  return data;
}

export async function getLeadSubscriptionJobAlert(id: string): Promise<{
  id: string;
  jobAlertOptIn: boolean;
}> {
  const res = await fetch(
    `${config.apiUrl}/lead-public/job-alert-subscription/${id}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
  const data = await res.json();
  if (data.statusCode >= 400) {
    const message = data?.message ? data.message : 'Subscription not found.';
    throw new Error(message);
  }
  return data;
}

export async function updateLeadSubscriptionJobAlert(leadSubscription: {
  id: string;
  jobAlertOptIn: boolean;
}) {
  const res = await fetch(
    `${config.apiUrl}/lead-public/job-alert-subscription`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(leadSubscription),
    }
  );
  const data = await res.json();
  if (data.statusCode >= 400) {
    const message = data?.message
      ? data.message
      : 'An error occurred while trying to save data';
    throw new Error(message);
  }
  return data;
}
