import * as yup from 'yup';
import { phoneRegExp } from 'constants/phone';

type InputProps = {
  phone: string;
  email: string;
};
export const validationSchema = ({ phone, email }: InputProps) => {
  return yup.object({
    firstName: yup.string().required(),
    email: yup.string().email(email).required(),
    phone: yup
      .string()
      .required(phone)
      .matches(phoneRegExp, 'Phone number is not valid'),
    zip: yup.string().required(),
  });
};
