import Image from 'next/image';
import { useTranslation } from 'react-i18next';
import lodash from 'lodash';
import { validationSchema } from './validationSchema';
import FormInputRow from '../InputRow';
import FormInput from '../Input';
import useFormik from '../../utils/hooks/useFormik';

import styles from './styles.module.scss';
import { useMemo } from 'react';
import clsx from 'clsx';
import { formatAsPhone } from '../../utils/formatAsPhone';

type InputProps = {
  values?: any;
  landingURL?: string;
  onSubmit: (v: any) => void;
};
const formValuesFields = ['firstName', 'email', 'zip'];

const presetDataFields = [
  'source',
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_term',
  'utm_content',
  'pt',
  'ct',
  'mt',
  'vt',
  'lastName',
  'orderId',
  'order',
];

export const SignedUp = ({ onSubmit, values, landingURL }: InputProps) => {
  const [t] = useTranslation('driver');
  const initialValues = useMemo(
    () => lodash.pick(values, formValuesFields),
    [values]
  );
  const presetData = useMemo(
    () => lodash.pick(values, presetDataFields),
    [values]
  );

  const { getFieldProps, getFieldErrorProp, handleSubmit } = useFormik({
    initialValues: {
      firstName: '',
      email: values?.Email || '',
      phone:
        formatAsPhone(values?.phoneNumber) ||
        formatAsPhone(values?.phone) ||
        '',
      zip: values?.Zip || '',
      ...initialValues,
    },
    validationSchema: validationSchema({
      email: t('errors.emailInvalid'),
      phone: t('errors.phoneRequired'),
    }),
    onSubmit(values) {
      onSubmit({
        landingURL,
        ...presetData,
        ...values,
        phone: `+1${values.phone}`,
      });
    },
  });

  return (
    <div className={styles.root}>
      <div className={styles.content}>
        <h2 className={styles.title}>{t('signedUp.title')}</h2>
        <div className={styles.subtitle}>{t('signedUp.subtitle')}</div>
        <form onSubmit={handleSubmit} className={styles.form}>
          <FormInputRow {...getFieldErrorProp('firstName')}>
            <FormInput
              {...getFieldProps('firstName')}
              className={styles.input}
              placeholder={t('signedUp.placeholders.firstName')}
            />
          </FormInputRow>
          <FormInputRow {...getFieldErrorProp('email')}>
            <FormInput
              {...getFieldProps('email')}
              className={styles.input}
              placeholder={t('signedUp.placeholders.email')}
            />
          </FormInputRow>
          <div className={styles.inputRow}>
            <FormInputRow
              required
              className={styles.phone}
              {...getFieldErrorProp('phone')}
            >
              <FormInput
                prefix="+1"
                {...getFieldProps('phone')}
                className={clsx(styles.input, styles.phonePrefix)}
                placeholder={t('signedUp.placeholders.phone')}
                onChange={(value) => {
                  getFieldProps('phone').onChange(
                    formatAsPhone(
                      value.toString(),
                      getFieldProps('phone').value
                    )
                  );
                }}
              />
            </FormInputRow>
            <FormInputRow className={styles.zip} {...getFieldErrorProp('zip')}>
              <FormInput
                {...getFieldProps('zip')}
                className={styles.input}
                placeholder={t('signedUp.placeholders.zip')}
              />
            </FormInputRow>
          </div>
          <button type="submit" className={styles.submitBtn}>
            <span>{t('signedUp.button')}</span>
            <Image
              src="/images/arrow-right.svg"
              alt=""
              width={18}
              height={10}
            />
          </button>
        </form>
      </div>
    </div>
  );
};
