import * as yup from 'yup';
import { phoneRegExp } from 'constants/phone';

type InputProps = {
  phone: string;
};

export const phoneValidationSchema = ({ phone }: InputProps) => {
  return yup.object({
    phone: yup
      .string()
      .required(phone)
      .matches(phoneRegExp, 'Phone number is not valid'),
  });
};
